<template>
  <v-card>
    <v-card-title style="font-size: 1.3rem">About This Site</v-card-title>
    <v-card-text style="font-size: 1.1rem">
      Outside of my startup, all of my professional developer experience has
      been in defense and intelligence contracting. Despite building
      applications using contemporary JS frameworks like Angular and Vue in
      those roles, none are publically accessible. This makes sharing samples of
      my work with potential employers impossible. For that reason, I decided to
      create this site where people could get a sense of who I am and what I'm
      capable of. I built this site using some of my favorite frontend
      technologies: Vue, Vuetify, D3, and RxJS.
    </v-card-text>
  </v-card>
</template>

<script>
export default {}
</script>
