var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"projects-svg-container"}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.showText)?_c('div',{staticClass:"text-area",style:({
        width: (_vm.radius + "px"),
        height: ((0.65 * _vm.radius) + "px"),
        transform: ("translate(-" + (0.5 * _vm.radius) + "px, -" + (0.2 * _vm.radius) + "px)"),
      })},[_c('div',{staticClass:"text-area-text",style:({
          width: (_vm.radius + "px"),
          height: ((0.65 * _vm.radius) + "px"),
        })},[_vm._v(" "+_vm._s(_vm.project.description)+" ")]),(_vm.project.description !== '')?_c('v-row',{attrs:{"justify":"center"}},[(_vm.project.link)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","large":"","color":"primary","href":_vm.project.link,"target":"_blank"}},on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,false,612826818)},[_c('span',[_vm._v("Demo")])]):_vm._e(),(_vm.project.github)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","large":"","color":"primary","href":_vm.project.github,"target":"_blank"}},on),[_c('v-icon',[_vm._v("mdi-github")])],1)]}}],null,false,3451417389)},[_c('span',[_vm._v("Code")])]):_vm._e(),(_vm.project.youtube)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","large":"","color":"primary","href":_vm.project.youtube,"target":"_blank"}},on),[_c('v-icon',[_vm._v("mdi-youtube")])],1)]}}],null,false,3224657805)},[_c('span',[_vm._v("Video")])]):_vm._e()],1):_vm._e()],1):_vm._e()]),_c('v-btn',{staticStyle:{"z-index":"13"},attrs:{"fixed":"","bottom":"","text":"","x-large":"","color":"primary"},on:{"click":function($event){_vm.showDialog = true}}},[_c('v-icon',[_vm._v("mdi-hammer-wrench")]),_c('span',{staticStyle:{"text-transform":"lowercase"}},[_vm._v("current projects")])],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"font-size":"1.3rem"}},[_vm._v("Current Projects")]),_c('v-card-text',{staticStyle:{"font-size":"1.1rem"}},[_c('p',[_vm._v("I am currently working on...")]),_c('ul',[_c('li',[_vm._v(" a "),_c('a',{attrs:{"href":"https://www.udemy.com/course/advanced-css-and-sass/","target":"blank_"}},[_vm._v("course")]),_vm._v(" on Advanced CSS and SASS ")]),_c('li',[_vm._v(" freelance data visualization projects for an online news startup using D3 ")]),_c('li',[_vm._v(" building a Redis clone from scratch using Go inspired by "),_c('a',{attrs:{"href":"https://codecrafters.io/","target":"blank_"}},[_vm._v("this brilliant idea")]),_vm._v(" to replace HackerRank with real world coding challenges ")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }