var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"sub-text",style:({ width: 1.3 * _vm.cardWidth + 'px' })},[_vm._v(" Here are ten takes on writing code to give you a sense of where I stand. For a more personal introduction, please click on whoami. To learn about my past, please click on work exp. If you want to see what I can do today, check out skills and projects. ")]),_vm._l((_vm.thoughts),function(thought,i){return _c('v-card',{key:("thought-" + (thought.title)),staticClass:"thought-card",class:{ selected: _vm.selected === i },style:(_vm.selected === i
        ? {
            'transform-origin': 'center right',
            'border-left': '5px solid #1976d2',
          }
        : {
            'border-left': '3px solid #1976d2',
            'transform-origin': 'center right',
            transform: ("translate(" + _vm.x + "px, " + _vm.y + "px) rotate(" + (_vm.selected > -1 ? 1.5 * i : 10 * i) + "deg)"),
            'z-index': _vm.thoughts.length - i,
          }),attrs:{"width":_vm.cardWidth,"elevation":_vm.selected === i ? 0 : 5,"tile":"","flat":_vm.selected === i},on:{"mouseup":function($event){_vm.selected = i}}},[_c('v-card-title',{staticClass:"justify-space-between",staticStyle:{"font-size":"1.3rem"}},[_c('span',[_vm._v(_vm._s(thought.title))]),(_vm.selected === i)?_c('v-btn',{attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.selected = -1}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1):_vm._e()],1),_c('v-card-text',{staticStyle:{"font-size":"1.1rem","line-height":"26.4px"},domProps:{"innerHTML":_vm._s(_vm.selected === i ? thought.text : thought.text.slice(0, 25))}}),(_vm.selected === i)?_c('v-card-text',{staticStyle:{"font-size":"1.1rem","line-height":"26.4px"}},[_c('i',{domProps:{"innerHTML":_vm._s(thought.quote)}})]):_vm._e()],1)})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"banner"}},[_c('div',[_vm._v("Hello, world!")]),_c('div',[_vm._v("I am Daren McCulley, a humble coder.")]),_c('div',[_vm._v("Welcome to my interactive self-promotion.")])])}]

export { render, staticRenderFns }